<template>
  <main id="home">
    <BContainer class="my-4" v-if="widget && !loading">
      <BRow>
        <BCol>
          <Header1 to="preferences" v-bind:showEdit="true">
            <h1 class="h2 mb-0">
              <BIconShop class="text-dark" />
              <BIconArrowRight class="mx-2" />
              <BLink v-bind:href="widget.reviewWebsite" target="_blank">{{ hostname }}</BLink>
            </h1>
          </Header1>
        </BCol>
      </BRow>
      <BRow class="mb-3 mb-lg-4">
        <BCol lg="4">
          <WidgetAverage class="chart" />
        </BCol>
        <BCol lg="4">
          <WidgetRating class="chart" />
        </BCol>
        <BCol lg="4" class="rounded">
          <WidgetOutbound class="chart" />
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <Header2 title="Events" />
          <WidgetEvent />
        </BCol>
      </BRow>
    </BContainer>
    <BContainer v-if="!widget && !loading">
      <BRow>
        <BCol md="6" offset-md="3">
          <BCard
            class="mt-5 mb-3"
            sub-title="See how ReviewBoostr can help your business grow"
            title="Start Your ReviewBoostr Subscription"
            title-tag="h2"
          >
            <BCardText class="my-3">
              <BIconCheck variant="success" /> Get more customer reviews<br />
              <BIconCheck variant="success" /> Increase your review site rating<br />
              <BIconCheck variant="success" /> Convince happy customers to leave positive reviews
            </BCardText>
            <BButton variant="primary" v-on:click="onCreateRecurringCharge">Start Subscription</BButton>
            <BCardText class="small text-muted mt-3">
              * Your trial/subscription expired. Follow the link to subscribe.
            </BCardText>
          </BCard>
        </BCol>
      </BRow>
    </BContainer>
  </main>
</template>

<script>
import { mapState } from "vuex";
import Header1 from "./Header1.vue";
import Header2 from "./Header2.vue";
import WidgetAverage from "./WidgetAverage.vue";
import WidgetEvent from "./WidgetEvent.vue";
import WidgetOutbound from "./WidgetOutbound.vue";
import WidgetRating from "./WidgetRating.vue";

export default {
  components: {
    Header1,
    Header2,
    WidgetAverage,
    WidgetEvent,
    WidgetOutbound,
    WidgetRating
  },
  props: {
    hmac: {
      type: String,
      required: false
    },
    host: {
      type: String,
      required: false
    },
    locale: {
      type: String,
      required: false
    },
    session: {
      type: String,
      required: false
    },
    shop: {
      type: String,
      required: false
    },
    timestamp: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      clientUrl: process.env.VUE_APP_CLIENT_URL
    };
  },
  computed: {
    ...mapState({
      loading: state => state.loading //,
    }),
    hostname() {
      if (this.widget.reviewWebsite !== null) {
        const url = new URL(this.widget.reviewWebsite);
        return url.hostname;
      }
      return "shop";
    },
    widget() {
      return this.$store.getters["widget/widgetByIndex"](0);
    }
  },
  async created() {
    if (this.$shopify === undefined) {
      // console.log("Shopify undefined... installing");
      this.$router.push({ name: "install", query: this.$route.query });
    }

    if (this.$shopify !== undefined && this.$store.state.widget.widgets === null) {
      // console.log("Shopify defined... getting widget");
      await this.$store.dispatch("widget/widgetsByShop");
    }
  },
  methods: {
    async onCreateRecurringCharge() {
      await this.$store.dispatch("createRecurringCharge");
    }
  }
};
</script>

<style scoped>
.chart {
  height: 275px;
}
</style>
