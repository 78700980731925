export default [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "page" */ "@/views/Page.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import(/* webpackChunkName: "page" */ "@/components/Home.vue"),
        props: route => ({
          hmac: route.query.hmac,
          host: route.query.host,
          locale: route.query.locale,
          session: route.query.session,
          shop: route.query.shop,
          timestamp: route.query.timestamp
        })
      },
      {
        path: "preferences",
        name: "preferences",
        component: () => import(/* webpackChunkName: "page" */ "@/components/Preferences.vue")
      },
      {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: () => import(/* webpackChunkName: "page" */ "@/components/NotFound.vue")
      }
    ]
  }
];
