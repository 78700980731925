import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import alert from "./alert";
import oauth from "./oauth";
import widget from "./widget";

Vue.use(Vuex);

const defaultState = () => {
  return {
    loading: false
  };
};

const mutations = {
  resetState(state) {
    Object.assign(state, defaultState());
  },
  setLoading(state, payload) {
    state.loading = payload;
  }
};

const actions = {
  resetGlobalState({ commit }) {
    commit("resetState");
    commit("alert/resetState", null, { root: true });
    commit("oauth/resetState", null, { root: true });
    commit("widget/resetState", null, { root: true });
  },
  async createRecurringCharge({ dispatch }) {
    const { data, error } = await this._vm.$apix.post("createRecurringCharge");
    if (error !== undefined) {
      dispatch("alert/createError", error, { root: true });
      return;
    }
    window.parent.location = data.confirmationUrl;
  }
};

const storageAvailable = type => {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};

export default new Vuex.Store({
  state: defaultState(),
  // plugins: [createPersistedState({ paths: ["oauth.nonce", "oauth.shop"] })],
  // plugins: window && window.localStorage ? [createPersistedState({ paths: ["oauth"] })] : [],
  // plugins: typeof window.localStorage !== "undefined" ? [createPersistedState({ paths: ["oauth"] })] : [],
  plugins: storageAvailable("localStorage") ? [createPersistedState({ paths: ["oauth"] })] : [],
  mutations,
  actions,
  modules: {
    alert,
    oauth,
    widget
  }
});
