export default {
  install: (Vue, options) => {
    const authHeader = async () => {
      let header = {};
      if (Vue.prototype.$shopify !== undefined) {
        header = {
          Authorization: `Bearer ${await Vue.prototype.$shopify.session()}`
        };
      }
      return header;
    };

    Vue.prototype.$apix = {
      post: async (path, data) => {
        const response = await fetch(`${options.apixUrl}/${path}`, {
          method: "POST",
          mode: "cors",
          headers: {
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/json; charset=utf-8",
            ...(await authHeader())
          },
          body: JSON.stringify(data)
        });
        return await response.json();
      },
      get: async path => {
        const response = await fetch(`${options.apixUrl}/${path}`, {
          method: "GET",
          mode: "cors",
          headers: {
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/json; charset=utf-8",
            ...(await authHeader())
          }
        });
        return await response.json();
      }
    };
  }
};
