import Vue from "vue";
import VueRouter from "vue-router";
import oauth from "./oauth";
import page from "./page";

Vue.use(VueRouter);

const routes = [...oauth, ...page]; // page contains catchall 404 and must be last

const router = new VueRouter({
  mode: "history",
  base: "/",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
  },
  routes
});

export default router;
