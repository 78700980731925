<template>
  <div>
    <h2 class="h3 text-dark">{{ title }}</h2>
    <hr class="bg-light" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>
