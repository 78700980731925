<template>
  <BForm novalidate v-on:submit.prevent>
    <BFormGroup label="Display on" v-slot="{ ariaDescribedby }" class="text-dark">
      <BFormRadioGroup
        id="displayScopeRadioGroup"
        name="displayScopeRadio"
        stacked
        v-bind:aria-describedby="ariaDescribedby"
        v-bind:options="displayScopeOptions"
        v-model="form.displayScope"
      />
    </BFormGroup>

    <BFormRow>
      <BCol lg="6">
        <BFormGroup label="Template">
          <BFormGroup id="step1TextGroup" label-for="step1Textarea" label="Step 1 - Rating">
            <BFormTextarea
              aria-describedby="step1InputLiveFeedback"
              id="step1Textarea"
              max-rows="2"
              rows="1"
              v-bind:state="$v.form.step1.$dirty ? !$v.form.step1.$error : null"
              v-model="form.step1"
            />
            <BFormInvalidFeedback id="step1InputLiveFeedback">
              Step 1 is required and must be between 20 and 60 characters
            </BFormInvalidFeedback>
          </BFormGroup>

          <BFormGroup id="step2TextGroup" label-for="step2Textarea" label="Step 2 - Review">
            <BFormTextarea
              aria-describedby="step2InputLiveFeedback"
              id="step2Textarea"
              max-rows="3"
              rows="2"
              v-bind:state="$v.form.step2.$dirty ? !$v.form.step2.$error : null"
              v-model="form.step2"
            />
            <BFormInvalidFeedback id="step2InputLiveFeedback">
              Step 2 is required and must be between 20 and 160 character
            </BFormInvalidFeedback>
          </BFormGroup>

          <BFormGroup id="reviewWebsiteInputGroup" label-for="reviewWebsiteInput" label="Review Website">
            <BFormInput
              aria-describedby="reviewWebsiteInputLiveFeedback"
              id="reviewWebsiteInput"
              type="text"
              v-bind:state="$v.form.reviewWebsite.$dirty ? !$v.form.reviewWebsite.$error : null"
              v-model="form.reviewWebsite"
            />
            <BFormInvalidFeedback id="reviewWebsiteInputLiveFeedback">
              Review website is required
            </BFormInvalidFeedback>
          </BFormGroup>

          <BFormGroup id="step3TextGroup" label-for="step3Textarea" label="Step 3 - Thanks">
            <BFormTextarea
              aria-describedby="step3InputLiveFeedback"
              id="step3Textarea"
              max-rows="2"
              rows="1"
              v-bind:state="$v.form.step3.$dirty ? !$v.form.step3.$error : null"
              v-model="form.step3"
            />
            <BFormInvalidFeedback id="step3InputLiveFeedback">
              Step 3 is required and must be between 3 and 60 characters
            </BFormInvalidFeedback>
          </BFormGroup>
        </BFormGroup>

        <BFormRow>
          <BCol>
            <BButton class="float-right" type="submit" v-bind:disabled="loading" v-bind:to="{ name: 'home' }">
              Cancel
            </BButton>
            <BButton type="submit" v-bind:disabled="loading" v-on:click="onUpdateWidget" variant="primary">
              Save
            </BButton>
            <BSpinner class="ml-2" label="saving..." small v-if="loading" />
          </BCol>
        </BFormRow>
      </BCol>
      <BCol lg="4" offset-lg="2">
        <BFormGroup label="Preview">
          <BFormRow class="mt-lg-3">
            <BCol class="p-3 bg-white rounded-lg shadow">
              <p class="text-center mb-2">{{ form.step1 }}</p>
              <BImg alt="Star rating" fluid v-bind:src="clientUrl + '/img/stars.png'" />
            </BCol>
          </BFormRow>
          <BFormRow class="mt-lg-3">
            <BCol class="mt-lg-3 p-3 bg-white rounded-lg shadow">
              <p class="text-center">{{ form.step2 }}</p>
              <div class="text-center">
                <BButton variant="primary" v-bind:href="form.reviewWebsite" target="_parent">YES</BButton>
              </div>
            </BCol>
          </BFormRow>
          <BFormRow class="mt-lg-3">
            <BCol class="mt-lg-3 p-3 bg-white rounded-lg shadow">
              <p class="text-center mb-0">{{ form.step3 }}</p>
            </BCol>
          </BFormRow>
        </BFormGroup>
      </BCol>
    </BFormRow>
  </BForm>
</template>

<script>
import { mapState } from "vuex";
import { maxLength, minLength, required, url } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  data() {
    return {
      clientUrl: process.env.VUE_APP_CLIENT_URL,
      displayScopeOptions: [
        { text: "Online store", value: "online_store" },
        { text: "Order status page", value: "order_status" },
        { text: "Both the storefront and order status page", value: "all" }
      ],
      form: {
        displayScope: this.$store.getters["widget/widgetByIndex"](0).displayScope,
        reviewWebsite: this.$store.getters["widget/widgetByIndex"](0).reviewWebsite,
        step1: this.$store.getters["widget/widgetByIndex"](0).template[0],
        step2: this.$store.getters["widget/widgetByIndex"](0).template[1],
        step3: this.$store.getters["widget/widgetByIndex"](0).template[2]
      }
    };
  },
  computed: {
    ...mapState({
      loading: state => state.loading
    })
  },
  mixins: [validationMixin],
  validations: {
    form: {
      reviewWebsite: {
        required,
        url
      },
      step1: {
        required,
        minLength: minLength(20),
        maxLength: maxLength(60)
      },
      step2: {
        required,
        minLength: minLength(20),
        maxLength: maxLength(160)
      },
      step3: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(60)
      }
    }
  },
  methods: {
    async onUpdateWidget() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.$store.dispatch("widget/updateWidget", {
          widget: this.form,
          id: this.$store.getters["widget/widgetByIndex"](0).id
        });
        this.$v.$reset();
      }
    }
  }
};
</script>
