import Vue from "vue";

const defaultState = () => {
  return {
    widgets: null
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    setWidget(state, payload) {
      Vue.set(state.widgets, payload.id, payload);
    },
    setWidgets(state, payload) {
      state.widgets = payload;
    }
  },
  actions: {
    async updateWidget({ commit, dispatch }, { widget, id }) {
      commit("setLoading", true, { root: true });
      const { data, error } = await this._vm.$apix.post(`updateWidget/${id}`, widget);
      if (error !== undefined) {
        dispatch("alert/createError", error, { root: true });
        commit("setLoading", false, { root: true });
        return;
      }
      commit("setWidget", data);
      commit("setLoading", false, { root: true });
      dispatch("alert/createSaved", null, { root: true });
    },
    async widgetsByShop({ commit }) {
      commit("setLoading", true, { root: true });
      const { data, error } = await this._vm.$apix.get("widgetsByShop");
      if (error !== undefined) {
        commit("setLoading", false, { root: true });
        return;
      }
      commit("setWidgets", data);
      commit("setLoading", false, { root: true });
    }
  },
  getters: {
    widgetByIndex: state => index => {
      if (state.widgets !== null) {
        return state.widgets[Object.keys(state.widgets)[index]];
      }
      return null;
    }
  }
};
