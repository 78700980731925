import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge-utils";
import store from "./store";

export default {
  install: (Vue, options) => {
    if (options.host !== null && options.session !== null && options.shop !== null) {
      store.commit("oauth/setShop", options.shop);
      const shopify = createApp({ apiKey: options.shopifyKey, host: options.host });
      Vue.prototype.$shopify = {
        session: async () => await getSessionToken(shopify)
      };

      // shopify.getState().then(state => {
      //   console.info("App State: %o", state);
      // });
    }
  }
};
