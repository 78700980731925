<template>
  <div id="install" class="align-self-center mx-auto">
    <p class="small">
      <span class="text-primary">code: {{ code }}</span>
      <BIconX v-if="$v.code.$invalid" variant="danger" />
      <BIconCheck v-else variant="success" />
    </p>
    <p class="small">
      <span class="d-inline-block text-truncate float-left text-primary" style="max-width: 250px;">
        hmac: {{ hmac }}
      </span>
      <BIconArrowClockwise v-if="$v.hmac.$pending" animation="spin" variant="warning" />
      <BIconX v-if="!$v.hmac.$pending && $v.hmac.$invalid" variant="danger" />
      <BIconCheck v-if="!$v.hmac.$pending && !$v.hmac.$invalid" variant="success" />
    </p>
    <p class="small">
      <span class="d-inline-block text-truncate float-left text-primary" style="max-width: 250px;">
        host: {{ host }}
      </span>
      <BIconX v-if="$v.host.$invalid" variant="danger" />
      <BIconCheck v-else variant="success" />
    </p>
    <p class="small">
      <span class="text-primary">shop: {{ shop }}</span>
      <BIconX v-if="$v.shop.$invalid" variant="danger" />
      <BIconCheck v-else variant="success" />
    </p>
    <p class="small">
      <span class="text-primary">state: {{ state }}</span>
      <BIconX v-if="$v.state.$invalid" variant="danger" />
      <BIconCheck v-else variant="success" />
    </p>
    <p class="small mb-0">
      <span class="text-primary">timestamp: {{ timestamp }}</span>
      <BIconX v-if="$v.timestamp.$invalid" variant="danger" />
      <BIconCheck v-else variant="success" />
    </p>
  </div>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";
import { shopValidator } from "../validators";
import { validationMixin } from "vuelidate";

export default {
  props: {
    code: {
      type: String,
      required: false
    },
    hmac: {
      type: String,
      required: false
    },
    host: {
      type: String,
      required: false
    },
    shop: {
      type: String,
      required: false
    },
    state: {
      type: String,
      required: false
    },
    timestamp: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      apixUrl: process.env.VUE_APP_APIX_URL,
      params: new URLSearchParams(this.$route.query)
    };
  },
  mixins: [validationMixin],
  validations() {
    return {
      code: {
        required
      },
      hmac: {
        required,
        async hmacValidator() {
          const response = await fetch(`${this.apixUrl}/verifyHmac?${this.params.toString()}`);
          if (response.status === 200) {
            return true;
          }
          return false;
        }
      },
      host: {
        required
      },
      shop: {
        required,
        shopValidator,
        sameAsShop: sameAs(function() {
          return this.$store.state.oauth.shop;
        })
      },
      state: {
        required,
        sameAsNonce: sameAs(function() {
          return this.$store.state.oauth.nonce;
        })
      },
      timestamp: {
        required
      }
    };
  },
  watch: {
    async "$v.$invalid"(value) {
      if (value === false) {
        await this.$store.dispatch("oauth/createShop", this.$route.query, { root: true });
      }
    }
  }
};
</script>

<style scoped>
#install p {
  mix-blend-mode: multiply;
}
</style>
