<template>
  <VChart class="chart" autoresize v-bind:option="option" v-if="stats" />
</template>

<script>
import { CanvasRenderer } from "echarts/renderers";
import { GaugeChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import { use, registerTheme } from "echarts/core";
import boostr from "../eChartsBoostr";
import VChart, { THEME_KEY } from "vue-echarts";

registerTheme("boostr", boostr);

use([CanvasRenderer, GaugeChart, GridComponent]);

export default {
  name: "WidgetAverage",
  components: {
    VChart
  },
  props: ["id"],
  provide: {
    [THEME_KEY]: "boostr"
  },
  computed: {
    stats() {
      return this.$store.getters["widget/widgetByIndex"](0).stats;
    },
    total() {
      return this.stats.s1 + this.stats.s2 + this.stats.s3 + this.stats.s4 + this.stats.s5;
    },
    s5Percent() {
      const s5 = this.stats.s5 / this.total;
      return isNaN(s5) === true ? 0 : s5;
    },
    s4Percent() {
      const s4 = this.stats.s4 / this.total;
      return isNaN(s4) === true ? 0 : this.s5Percent + s4;
    },
    s3Percent() {
      const s3 = this.stats.s3 / this.total;
      return isNaN(s3) === true ? 0 : this.s4Percent + s3;
    },
    s2Percent() {
      const s2 = this.stats.s2 / this.total;
      return isNaN(s2) === true ? 0 : this.s3Percent + s2;
    },
    average() {
      return (
        (1 * this.stats.s1 + 2 * this.stats.s2 + 3 * this.stats.s3 + 4 * this.stats.s4 + 5 * this.stats.s5) /
        this.total
      ).toFixed(1);
    },
    option() {
      return {
        grid: {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0
        },
        series: [
          {
            type: "gauge",
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: [
                  [this.s5Percent, "rgba(31,111,235,1)"],
                  [this.s4Percent, "rgba(31,111,235,0.8)"],
                  [this.s3Percent, "rgba(31,111,235,0.6)"],
                  [this.s2Percent, "rgba(31,111,235,0.4)"],
                  [1, "rgba(31,111,235,0.2)"]
                ],
                width: "40"
              }
            },
            axisTick: {
              show: false
            },
            data: [
              {
                name: `${isNaN(this.average) ? 0 : this.average} out of 5`
              }
            ],
            detail: {
              show: false
            },
            endAngle: -270,
            startAngle: 90,
            pointer: {
              show: false
            },
            splitLine: {
              show: false
            },
            title: {
              offsetCenter: [0, 0],
              color: "#ffffff",
              fontSize: 18,
              fontWeight: "bold"
            }
          }
        ]
      };
    }
  }
};
</script>
