export default [
  {
    path: "/oauth",
    component: () => import(/* webpackChunkName: "oauth" */ "@/views/OAuth.vue"),
    children: [
      {
        path: "",
        name: "install",
        component: () => import(/* webpackChunkName: "oauth" */ "@/components/Install.vue"),
        props: route => ({
          hmac: route.query.hmac,
          shop: route.query.shop,
          timestamp: route.query.timestamp
        })
      },
      {
        path: "authorize",
        name: "authorize",
        component: () => import(/* webpackChunkName: "oauth" */ "@/components/Authorize.vue"),
        props: route => ({
          code: route.query.code,
          hmac: route.query.hmac,
          host: route.query.host,
          shop: route.query.shop,
          state: route.query.state,
          timestamp: route.query.timestamp
        })
      }
    ]
  }
];
