import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Vue from "vue";
import VueMeta from "vue-meta";
import APIXPlugin from "./APIXPlugin";
import App from "./App.vue";
import "./assets/style.scss";
import "./registerServiceWorker";
import router from "./router";
import ShopifyPlugin from "./ShopifyPlugin";
import store from "./store";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(VueMeta);

const params = new URLSearchParams(window.location.search);
Vue.use(ShopifyPlugin, {
  shopifyKey: process.env.VUE_APP_SHOPIFY_KEY,
  apixUrl: process.env.VUE_APP_APIX_URL,
  host: params.get("host"),
  session: params.get("session"),
  shop: params.get("shop")
});

Vue.use(APIXPlugin, {
  apixUrl: process.env.VUE_APP_APIX_URL
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
