<template>
  <div>
    <Alert class="position-fixed app-alert" />
    <RouterView />
  </div>
</template>

<script>
import Alert from "./components/Alert.vue";

export default {
  metaInfo() {
    return {
      title: process.env.VUE_APP_CLIENT_NAME
    };
  },
  components: {
    Alert
  }
};
</script>

<style scoped>
.app-alert {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
</style>
