import { uuid } from "@/uuid";

const defaultState = () => {
  return {
    nonce: null,
    shop: null
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    setNonce(state, payload) {
      state.nonce = payload;
    },
    setShop(state, payload) {
      state.shop = payload;
    }
  },
  actions: {
    async createShop({ dispatch }, params) {
      const { data, error } = await this._vm.$apix.post("createShop", params);
      if (error !== undefined) {
        console.error("createShop", error);
        dispatch("alert/createError", error, { root: true });
        return;
      }
      window.location = data.confirmationUrl;
    },
    install({ commit }, shop) {
      const nonce = uuid();
      commit("setNonce", nonce);
      commit("setShop", shop);
      window.location = `https://${shop}/admin/oauth/authorize?client_id=${process.env.VUE_APP_SHOPIFY_KEY}&scope=read_script_tags,write_script_tags&redirect_uri=${process.env.VUE_APP_CLIENT_URL}/oauth/authorize&state=${nonce}&grant_options[]=`;
    }
  }
};
