<template>
  <BContainer class="mt-5 mb-2">
    <BRow>
      <BCol>
        <BRow>
          <BCol>
            <Header1 title="Preferences" to="home" v-bind:showPerma="true" class="text-dark" />
            <WidgetForm v-if="widget" />
          </BCol>
        </BRow>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
import Header1 from "./Header1.vue";
import WidgetForm from "./WidgetForm.vue";

export default {
  components: {
    Header1,
    WidgetForm
  },
  computed: {
    widget() {
      return this.$store.getters["widget/widgetByIndex"](0);
    }
  }
};
</script>
