<template>
  <BCard no-body class="rounded-0">
    <BListGroup v-if="!widget.events || Object.keys(widget.events).length === 0" flush>
      <BListGroupItem class="d-flex flex-column bg-transparent border-none text-center">
        <small class="text-muted">
          Survey results and outbound events will be displayed here.
        </small>
      </BListGroupItem>
    </BListGroup>
    <BListGroup v-else flush>
      <BListGroupItem class="d-flex flex-column bg-transparent" v-bind:key="event.id" v-for="event in widget.events">
        <div class="d-flex flex-row">
          <div class="d-flex flex-column flex-md-row flex-grow-1">
            <div>
              <span v-if="event.request.city">{{ event.request.city }}</span>
              <span v-if="event.request.city && event.request.region">, </span>
              <span v-if="event.request.region">{{ event.request.region }}</span>
              <span v-if="!event.request.city && !event.request.region">{{ event.request.timezone }}</span>
            </div>
            <div v-if="event.data.rating" class="ml-md-3">
              <BIcon
                v-for="index in 5"
                v-bind:key="index"
                v-bind:icon="event.data.rating >= index ? 'star-fill' : 'star'"
                class="mr-2"
                variant="warning"
              />
              <small>{{ event.data.rating }} out of 5</small>
            </div>

            <div v-if="event.data.outbound" class="ml-md-3">
              <BIconArrowRight class="mr-2" />
              <BLink v-bind:href="reviewWebsite" target="_blank">{{ hostname }}</BLink>
            </div>
          </div>
          <div>
            <span v-bind:title="localDatetime(event.data.date, true)">
              {{ localDatetime(event.data.date) }}
            </span>
          </div>
        </div>
      </BListGroupItem>
    </BListGroup>
  </BCard>
</template>

<script>
import { mapState } from "vuex";
import { Temporal } from "@js-temporal/polyfill";

export default {
  props: ["id"],
  computed: {
    ...mapState({
      reviewWebsite: state => state.widget.reviewWebsite
    }),
    hostname() {
      if (this.widget.reviewWebsite !== null) {
        const url = new URL(this.widget.reviewWebsite);
        return url.hostname;
      }
      return "shop";
    },
    sortedEvents() {
      return Object.keys(this.widget.events)
        .sort()
        .reverse()
        .reduce((obj, key) => {
          obj[key] = this.widget.events[key];
          return obj;
        }, {});
    },
    widget() {
      return this.$store.getters["widget/widgetByIndex"](0);
    }
  },
  methods: {
    localDatetime(string, short = false) {
      const created = Temporal.Instant.from(string.replace(/^event:rating;|event:outbound;/, "").split(";")[0]);
      const local = created.toZonedDateTimeISO(Temporal.Now.timeZone());
      if (short === true) {
        return local.toLocaleString();
      }
      return local.toLocaleString([], { month: "short", day: "numeric" });
    }
  }
};
</script>
