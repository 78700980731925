import { helpers } from "vuelidate/lib/validators";

const shopValidator = helpers.regex("shopValidator", /^[a-zA-Z0-9][a-zA-Z0-9-]*\.myshopify\.com$/);
// const hmacValidator = (params) => async () => {
//   const response = await fetch(`${this.apiUrl}/verifyHmac?${params}`);
//   if (response.status === 200) {
//     return true;
//   }
//   return false;
// }

export { /*hmacValidator,*/ shopValidator };
