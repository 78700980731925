<template>
  <div class="min-vh-100 d-flex">
    <BContainer class="align-self-center">
      <BRow class="justify-content-center my-3">
        <BCol sm="9" md="7" lg="5" xl="4">
          <div class="card bg-transparent text-white border-0">
            <Logo fluid class="card-img" />
            <div class="card-img-overlay d-flex">
              <RouterView />
            </div>
          </div>
        </BCol>
      </BRow>
    </BContainer>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";

export default {
  components: {
    Logo
  }
};
</script>
